import React from 'react';
import Link from 'gatsby-link';
import AuthorLocation from '../AuthorLocation/AuthorLocation';
import AuthorWebsite from '../AuthorWebsite/AuthorWebsite';
import AuthorLink from '../AuthorLink/AuthorLink';

//  const LinkedinIcon = generateShareIcon('linkedin');
//  const QuoraIcon = generateShareIcon('livejournal');
const Bio = props => {
  const { bio, morePostsUrl } = props;
  if (bio) {
    return <p>{bio}</p>;
  } else {
    return (
      <p>
        A home appliance enthusiast and creative writer.
      </p>
    );
  }
  return (
    <p>
      Read <Link to={morePostsUrl}>more posts</Link> by this author.
    </p>
  );
};

class AuthorInfo extends React.Component {
  render() {
    const { prefix } = this.props;
    const { id, name, image, bio, url, location } = this.props.author;
    const authorInfoUrl = prefix ? `${prefix}/${id}` : id;
    const iconSize =  36
    //if (image) {
    return (
      <section className="author">
        <h4>
          <AuthorLink url={authorInfoUrl} name={name} />
          Mime Petit
        </h4>
        <Bio bio={bio} morePostsUrl={authorInfoUrl} />
     
          <AuthorLocation location={location} />
          <AuthorWebsite url={url} />
  
      </section>
    );
    //}
    return null;
  }
}

export default AuthorInfo;
