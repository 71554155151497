import styled from '@emotion/styled';
import * as React from 'react'

const Button = styled.button`
  /* Adapt the colors based on primary prop */
  background: ${props => props.primary ? "palevioletred" : "white"};
  color: ${props => props.primary ? "white" : "black"};
  font-size: 1em;
  border-radius: 3px;
  width: ${props => props.width ? "100%" : "6rem"};
  display: flex;
  justify-content: center;
  text-decoration: none;
  &:hover {
    text-decoration:none;
  }
 
  font-size: 0.8rem;
    padding: 0.2rem 0;
  font-weight: 500;
  border-radius: 100px;


    background: #adc;
    font-weight: 500;
    font-size: 1rem;
    font-style: normal;
    border: none;

`;

class PanelButton extends React.PureComponent {
  constructor(props) {
    super(props)
  }

  render() {
    const { btnText, primary } = this.props;
    return <Button primary={primary}><span>{btnText}</span></Button>
  }
}

export default PanelButton;