import styled from '@emotion/styled'
import * as React from 'react'
import { Link } from 'gatsby'
import PanelButton from './panelButton'
import IconWithText from '../IconWithText/IconWithText';
import Img from 'gatsby-image'

const Title = styled.h2`
    font-size: 1.3rem;
    color: #fff;
    text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.35);
    line-height: 1.3;
    font-weight: 600;
    position: relative;
    z-index: 1;
`;

const StyledReactLink = styled(Link)`
text-decoration: none;
&:link{
    text-decoration: none!important;
  }
color:none;
`;

const StyledLink = styled.a`
    text-decoration: none;
    font-style: normal;

    color: white;
    &:hover {
        text-decoration: none;
        color: white;
    }
`;
const BlogPanelWrapper = styled.div`
    padding: 0.9rem;
    height: 237px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 8px;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    z-index: 2;
    margin-bottom: 30px;
`;



const ImageWrapper = styled.div`
left: 0;
top: 0;
bottom: 0;
right: 0;
height: 250px;
animation:an 1s forwards;
transition:1s ease forwards; 

position: absolute;
top: 0;
left: 0;
width: 100%;
z-index: -1;
height: 250px



// Adjust image positioning (if image covers area with defined height) and add font-family for polyfill
> div {
   display:none;
}
&:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000;
    width: 100%;
    height: 100%;
    background-color: #717171;
    opacity: 0.2;
}  
`;

// Create a Title component that'll render an <h1> tag with some styles
const BottomPanel = styled.div`
    display: flex;
    justify-content: space-between;
    z-index: 1;
    position: relative;
    align-items: flex-end;
`;

// const BannerImage = styled.styled(Img)`
//     position: absolute;
//     object-fit: cover;
//     width: 100%;
//     height: 100%;
//     top: 0%;
//     left: 0%;
//     z-index: 0;`

class ProductPanel extends React.Component {
    constructor(props) {
        super(props)
        this.state = { loaded: false, resp: [], items: [{ fields: {} }] }
    }

    render() {
        const { product } = this.props;
        const { title, category_image, goTo } = product.node.data;
        console.log(category_image)
        return <StyledReactLink to={goTo.text}>
            <BlogPanelWrapper key={title.text}>
        
                <ImageWrapper> 
                    <Img fluid={category_image.localFile.childImageSharp.fluid} /> </ImageWrapper>
                <Title>
                    <StyledLink>{title.text}</StyledLink>
                </Title>

                <BottomPanel>
                    {/* <IconWithText
                        color={''}
                        iconName={'FaBeer'}
                        text={title.text} /> */}
                    <PanelButton
                        btnText={title.text}
                        autoWidth={false}
                        primary={false}
                        canClick={true}
                    />
                </BottomPanel>
            </BlogPanelWrapper>
        </StyledReactLink>
    }
}

export default ProductPanel