module.exports = {
  pathPrefix: '/', // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "portfolio"
  title: 'Little Home Appliance', // Navigation and Site Title
  titleAlt: 'Little Home Appliance', // Title for JSONLD
  headline: 'Little Home Appliance',
  description: 'A comprehensive overview on the daily functions of our little household appliances.',
  url: 'https://littlehomeappliance.com', // Domain of your site. No trailing slash!
  siteLanguage: 'en', // Language Tag on <html> element
  logo: '/logos/logo-1024.png', // Used for SEO
  ogLanguage: 'en_US', // Facebook Language
  author: 'LHA',
  // JSONLD / Manifest
  favicon: 'src/favicon.png', // Used for manifest favicon generation
  shortName: 'Prismic', // shortname for manifest. MUST be shorter than 12 characters
  themeColor: '#3D63AE',
  backgroundColor: '#EBEDF2',

  twitter: '@starter_prismicio', // Twitter Username
  facebook: 'gatsby-prismic', // Facebook Site Name
  googleAnalyticsID: 'UA-106592496-6',

  skipNavId: 'reach-skip-nav', // ID for the "Skip to content" a11y feature
}
