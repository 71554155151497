import React from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'

const TableWrapper = styled.table`
  Table-bottom: 2rem;
  max-width: calc(${props => props.theme.maxWidthText} - 50px);
`

const renderTableData = (items) => {
    return items.map((item, index) => {
       const { col1, col2 } = item; //destructuring
     
       const { text } = col1;
       const { text2 } = col2
       return (
          <React.Fragment>
            <tr>
               {Object.values(item).map((ele, i) => <td>{i === 0 ? <b>{ele.text}</b>: ele.text} </td>)}
            </tr>
          </React.Fragment>
       )
    })
 }
 
const Table = ({ input }) => { 
const { items } = input;
debugger;
return (<div>
<h3 id='title'>{input.primary.table_title.text}</h3>

<TableWrapper id='students'>
   <tbody>
      {renderTableData(items)}
   </tbody>
</TableWrapper>
</div>)


}


export default Table

Table.propTypes = {
  input: PropTypes.object.isRequired,
}
