import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import './Modal.css'

const encode = (data) => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}


export class ModalContent extends Component {
  constructor(props) {
    super(props);
    this.state = { name: "", email: "", message: "", open: false };
    this.timeOut = undefined;
  }

  componentDidMount() {
    var modalAlreadyShown = localStorage.getItem('bgcolor');
    this.timeOut = setTimeout(() => this.toggleModal(), 12000);
 
   
    if(modalAlreadyShown) {
      window.clearTimeout(this.timeOut);
    }
    
  }


  handleSubmit = e => {
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contact", ...this.state })
    })
      .then(this.toggleModal())
      .catch(error => alert(error));

    e.preventDefault();
  };
  handleChange = e => this.setState({ [e.target.name]: e.target.value });

  toggleModal() {
    localStorage.setItem('bgcolor', true);
    this.setState({ open: !this.state.open })
  }

  render() {
    const { name, email, message, open } = this.state;

    return  <React.Fragment>
      <aside className={`modal-cover ${open ? '' : 'hidden'}`}>
        <div className="modal-area">
          <button className="_modal-close">
            <span onClick={() => this.toggleModal()} className="_hide-visual">Close</span>
            <svg onClick={() => this.toggleModal()} className="_modal-close-icon" viewBox="0 0 40 40">
              <path d="M 10,10 L 30,30 M 30,10 L 10,30" />
            </svg>
          </button>


          <form className="modal-body" name="contact" method="post" data-netlify="true" data-netlify-honeypot="bot-field" onSubmit={this.handleSubmit}>
            {/* You still need to add the hidden input with the form name to your JSX form */}
            <input type="hidden" name="form-name" value="contact" />
            <div className="description">
              <p>Interested in Augmented Reality and how it can be used for household appliances?</p>
            </div>
            <p>
              <label>
                Your Name: <input type="text" required name="name" value={name} onChange={this.handleChange} />
              </label>
            </p>
            <p>
              <label>
                Your Email: <input type="email" required name="email" value={email} onChange={this.handleChange} />
              </label>
            </p>
            <p>
              <button className="modal-button" type="submit">Send</button>
            </p>
          </form>
        </div>
      </aside></React.Fragment>
    
  }
};
export default ModalContent;