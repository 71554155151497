import React from 'react';

class AuthorImage extends React.Component {
  render() {
    const { name, image, url } = this.props.author;
    let imageProfile = image
      ? image
     : null;
    if (imageProfile) {
      return (
        <figure className="author-image">
          <a
            className="img"
            href={url}
            style={{ backgroundImage: `url("${imageProfile}")` }}
          >
            <span className="hidden">{`${name}'s Picture`}</span>
          </a>
        </figure>
      );
    }
    return null;
  }
}

export default AuthorImage;
