import React from 'react'
import { Link } from 'gatsby'
import logo from '../../static/little-home.png';
import styled from '@emotion/styled'
import { withPrefix } from 'gatsby'

const Image = styled.img`
  height: 250px;

  @media screen and (max-width: 600px) {
   height: auto;
  }
`
const NavbarItems = styled.div`
display: flex;
align-items: center;
width: 40%;
justify-content: space-around;

@media screen and (max-width: 600px) {
  flex-direction: column;
  margin-bottom: 20px;
  height: 90px;
}
 a {
   color: black;
   font-weight: 400;
 }
`


const NavbarWrapper = styled.div`
display: flex;


@media screen and (max-width: 600px) {
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

`



const Navbar = class extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      active: false,
      navBarActiveClass: '',
    }
  }

  toggleHamburger = () => {
    // toggle the active boolean in the state
    this.setState(
      {
        active: !this.state.active,
      },
      // after state has been updated,
      () => {
        // set the class in state for the navbar accordingly
        this.state.active
          ? this.setState({
            navBarActiveClass: 'is-active',
          })
          : this.setState({
            navBarActiveClass: '',
          })
      }
    )
  }

  render() {
    return (
      <nav
        className="navbar is-transparent"
        role="navigation"
        aria-label="main-navigation"
      >
        <div className="container">
          <div className="mobile-container">
            {/* Hamburger menu */}
            <div
              className={`navbar-burger burger ${this.state.navBarActiveClass}`}
              data-target="navMenu"
              onClick={() => this.toggleHamburger()}
            >
              <span />
              <span />
              <span />
            </div>

          </div>

          <div
            id="navMenu"
            className={`navbar-menu ${this.state.navBarActiveClass}`}
          >
            <NavbarWrapper>
              <div className="logo">
              <Image src={logo} />
              </div>
              <NavbarItems>
             
                <Link to="/" className="navbar-item" title="Logo">
                 Home
                </Link>
                <Link to="/about" className="navbar-item" title="Logo">
                About
                </Link>
                <Link to="/recommended" className="navbar-item" title="Logo">
                Recommended
                </Link>
                <a style={{ float: "right", marginRight: '10px',  color: 'black' }} href="mailto:littlehomeappliance@gmail.com">littlehomeappliance@gmail.com</a>
              </NavbarItems>
            </NavbarWrapper>

            <div className="navbar-end has-text-centered">
            </div>
          </div>
        </div>
      </nav>
    )
  }
}

export default Navbar
