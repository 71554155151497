import styled from '@emotion/styled'
import * as React from 'react'
import * as FontAwesome from 'react-icons/fa';

const IconWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-left: 0.4rem;
    color: white;
`;

const Icontext = styled.div`
    font-size: 1.4rem;
    text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.35);
    color: #fff;
    margin-left: 0.7rem;
`;


class IconWithText extends React.PureComponent {
    constructor(props) {
        super(props)
    }

    render() {
        const { text, iconName } = this.props;
        const icon = React.createElement(FontAwesome[iconName]);
        return (
            <IconWrapper>
                <div>
                    {icon}
                </div>

                <Icontext>
                    {text}
                </Icontext>
            </IconWrapper>
        )
    }
}

export default IconWithText;